.documents-card {
    background-color: rgba(255,255,255,0.1);
    padding: 24px 36px;
    border-radius: 16px;
    margin: 36px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
}

.documents-item {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    position: relative;
}

.documents-item p{
    margin-left: 32px;
}

.documents-item svg{
    color: rgba(21, 48, 66, 0.9);
    position: absolute;
    left: 0;
    transition: all 0.35s ease-out;
}

.documents-item .download {
    margin-top: 2px;
    opacity: 0;
    font-size: 24px;
}

.documents-item:hover .download {
    opacity: 1;
    transform: rotate(360deg);
}

.documents-item:hover .file {
    opacity: 0;
    transform: rotate(360deg);
}
