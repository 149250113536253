.body__offer {
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 20px;
}

.offer-items__main-headline {
    color: var(--color-white);
    margin-bottom: 20px;
}

.container__offer-items {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 16px;
}

.body__offer-item {
    background: var(--color-white);
    border: 1px solid #BCBCBD;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: var(--padding-card-offer-adaptive);
}

.offer-item__headline {
    font-weight: 600;
    color: var(--color-dark-blue);
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.offer-item__description {
    font-weight: 500;
    line-height: 18px;
}

@media (min-width: 768px){
    .body__offer {
        padding-top: 54px;
        padding-bottom: 62px;
    }
    .container__offer-items {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 24px;
    }
    .body__offer-item {
        padding: var(--padding-card-offer);
    }
    .offer-items__main-headline {
        margin-bottom: 42px;
    }
}
