.project-item {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 32px;
    margin-top: 42px;
}

.headline-project {
    color: var(--color-white);
    margin-bottom: 18px;
    font-size: 24px;
    max-width: 388px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.description-project {
    margin-bottom: 25px;
    color: var(--color-white);
    line-height: 28px;
}

.project-item__left-img {
    margin: 0 auto;
    width: 100%;
}

.project-item__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.body__project-status {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.body__project-status:after {
    content: '';
    display: block;
    background: var(--color-white);
    height: 1px;
    max-width: 216px;
    width: 100%;
}

.project-status__p {
    color: var(--color-white);
    margin-right: 12px;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px){
    .project-item {
        grid-template-columns: repeat(2,1fr);
    }
}

@media (min-width: 1200px){
    .project-item__left-img {
        height: 400px;
        object-fit: cover;
    }
}