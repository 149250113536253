.about-item {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 32px;
    margin-top: 42px;
}

.headline-about {
    color: var(--color-white);
    margin-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 24px;
}

.mobile-img {
    margin-bottom: 16px;
}

.descktop-img {
    display: none;
}

.about-item .headline-project__second {
    margin-bottom: 8px;
    color: var(--color-white);
    font-weight: 700;
}

.description-about {
    margin-bottom: 8px;
    color: var(--color-white);
    line-height: 28px;
}

.about-item__left-img {
    margin: 0 auto;
}

.headline__about {
  font-size: 14px;
}

.headline__about-name {
    color: var(--color-white);
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.about-item__right {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-item__right-ul li{
    color: var(--color-white);

}

.about-item__right .list-vacan__about {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

@media (min-width: 768px){
    .about-item {
        grid-template-columns: repeat(2,1fr);
    }
    .descktop-img {
        display: block;
    }
    .mobile-img {
        display: none;
    }
}
