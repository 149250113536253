.body__form {
    background-image: url("../../Images/backgroundForm.jpg");
    background-size: cover;
    position: relative;
    z-index: 2;

    padding-top: 36px;
    padding-bottom: 36px;
}

.body__form:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container__form {
    display: flex;
    flex-direction: column;
}

.container-form__left-block {
    width: 100%;
    max-width: 100%;
}

.container-form__left-block .MuiSvgIcon-root {
    color: var(--color-white);
    width: 40px;
    height: 40px;
}

.linkedin-link__form {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: 22px;
}

.container-form__right-block {
    width: 544px;
    max-width: 100%;
    background: rgba(9, 20, 26, 0.9);
    border-radius: 16px;
    padding: 20px 20px 44px;
    margin: 0 auto;
    margin-top: 32px;
}

.container-form__right-block input {
    color: var(--color-white);
}

.container-form__right-block .MuiInput-underline:after {
    content: none;
}

.container-form__right-block .MuiInput-underline:before {
    border-color: var(--color-white);
}

.container-form__right-block .MuiFormHelperText-root {
    color: var(--color-white);
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 10px;
}

.container-form__right-block .MuiSelect-icon {
    color: var(--color-white);
}

.container-form__right-block .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid var(--color-white);
}

.container-form__right-block .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-white);
}

.container-form__right-block .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-white);
}

.form__main-headline {
    color: var(--color-white);
    margin-bottom: 24px;
    max-width: 232px;
    line-height: 32px;
}

.container-form__left-block .form__main-description {
    line-height: 24px;
    color: var(--color-white);
    font-weight: 400;
}

.container-form__right-block__headline {
    color: var(--color-white);
}

.form__inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    margin-bottom: 24px;
}

.form__inputs-textarea {
    width: 100%;
}

.form__inputs-textarea textarea {
    color: var(--color-white);
}

.form__select-body svg {
    color: var(--color-white);
}

.select-body {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    width: 100%;
    color: var(--color-white);
    border-bottom: 1px solid var(--color-white);
    padding: 4px 0 7px;
    outline: 0;
    cursor: pointer;
    -webkit-border-radius: 0;
    height: 32px;
}

.select-body::-ms-expand {
    display: none;
}

.select-body__option {
    background: rgba(9,20,26,.65);
    -webkit-border-radius: 0;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
    color: var(--color-white);
}

.container-form__right-block__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
}

.right-block__buttons-form {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--color-white);
    margin-top: 28px;
}

.right-block__buttons-form a {
    width: 100%;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
}

.right-block__file {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--color-white);
}

.right-block__file label{
    margin-left: 4px;
}

.right-block__file .MuiSvgIcon-root {
    transform: rotate(30deg);
}

@media (min-width: 1024px) {
    .body__form {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .container__form {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .container-form__left-block {
        width: 472px;
    }

    .form__main-headline {
        margin-bottom: 30px;
    }

    .linkedin-link__form {
        margin: 0;
        margin-top: 34px;
        margin-right: auto;
    }

    .container-form__right-block {
        margin-top: 0;
    }

    .form__main-headline {
        line-height: 60px;
        max-width: 100%;
    }

    .container-form__right-block {
        padding: 45px 24px 32px;
    }

    .form__inputs {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        margin-bottom: 36px;
    }

    .container-form__right-block__buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 16px;
    }

    .right-block__buttons-form {
        width: 100%;
        display: flex;
        align-items: center;
        color: var(--color-white);
        margin-top: 0;
    }
}
