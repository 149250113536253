.container__news-page {
    display: flex;
    align-items: normal;
    flex-direction: column;
    margin-top: 20px;
}

.container__news-page a {
    text-decoration: none;
}

.news-page__block-img {
    margin: 0 auto;
    max-width: 424px;
    width: 100%;
    position: relative;
    margin-bottom: 18px;
}

.news-page__description-block {
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
}

.description-block__date-block {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.date-block__date {
    margin-right: 12px;
    text-shadow: 0px 1px 1px #000000;
    color: var(--color-white);
}

.description-block__date-block:after {
    content: '';
    display: block;
    background: var(--color-white);
    width: 216px;
    max-width: 100%;
    height: 1px;
    box-shadow: 0px 1px 1px #000000;
}

.news-page__headline {
    color: var(--color-white);
    text-shadow: 0px 1px 2px #000000;
    line-height: 36px;
    margin-bottom: 18px;
}

.news-page__description {
    color: var(--color-white);
    line-height: 20px;
    text-shadow: 0px 1px 1px #000000;
    margin-bottom: 38px;
    margin-top: 15px;
}

.news-page__description-bottom {
    display: flex;
    align-items: center;
}

.news-page__hash {
    width: 576px;
    max-width: 100%;
    margin-right: 8px;
    color: var(--color-dark-gray);
}

.news-page__link svg {
    color: var(--color-white);
    width: 14px;
}

.news-page__link {
    display: none;
}

.block-img__img-desktop {
    display: none;
}

@media (min-width: 1024px){
    .container__news-page {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        margin-top: 34px;
    }
    .block-img__img {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
    }
    .news-page__block-img {
        margin: 0;
        margin-right: 44px;
    }
    .news-page__description-block {
        margin: 0;
    }
    .news-page__link {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-white);
    }
    .news-page__description {
        margin-top: 0;
    }
    .block-img__img-mobile {
        display: none;
    }
    .block-img__img-desktop {
        display: block;
    }
}