.all-footer {
  padding-top: 15px;
  background: rgba(255, 255, 255, 0.9);
}

.footer_logo {
  max-width: 227px;
}

.footer_left-part {
  padding-bottom: 11px;
  border-bottom: 1px solid #A97278;
  position: relative;
}

@media (max-width: 500px) {
  .footer_left-part > a > svg {
    max-width: 50vw;
  }
}

.footer_links {
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.footer_all-links {
  padding-top: 23px;
}

.footer_all-links .list-language__items {
  position: absolute;
  top: 0;
  right: 0;
}

.footer_all-links .item-language {
  font-size: 12px;
  color: var(--color-black);
}

.footer_all-links .active-language:after {
  background: var(--color-black);
}

.footer_links .item-menu__link {
  color: var(--color-black);
  font-size: 12px;
  position: relative;
  padding-bottom: 4px;
}

.footer_links .item-menu__link:after {
  content: '';
  display: block;
  position: absolute;
  width: 80%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: var(--color-black);
  transition: 0.3s;
}

.footer_links .item-menu__link-active:after {
  content: '';
  display: block;
  position: absolute;
  width: 80%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: var(--color-black);
}

.footer_links .item-menu:not(:last-child):after {
  background: var(--color-black);
}

.footer_right-part {
  padding: 15px 0;
  position: relative;
}

.linkedin-link {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 40px;
}

.linkedin-link .MuiSvgIcon-root {
  font-size: 40px;
  color: var(--color-black);
}

.list-contact {
  list-style: none;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact-item:not(:last-of-type) {
  margin-bottom: 5px;
}

.contact-item .MuiSvgIcon-root {
  font-size: 16px;
  margin-right: 8px;
}

.contact-item a, .contact-item span {
  color: var(--color-black);
  font-size: 12px;
  text-decoration: none;
}

.bottom-footer {
  padding: 2px 0;
  background: rgba(255, 255, 255, 0.95);
}

.bot-p {
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  line-height: initial;
}

@media (min-width: 768px) {
  .top-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer_right-part {
    padding: 0;
  }

  .footer_left-part {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    padding-bottom: 0;
  }

  .footer_all-links .list-language__items {
    position: relative;
  }

  .footer_all-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 23px;
  }

  .all-footer {
    background: rgba(255, 255, 255, 0.95);
    padding-top: 18px;
    padding-bottom: 20px;
  }

  .bottom-footer {
    background: #DEDEDE;
  }

  .contact-item a, .contact-item span {
    font-size: 16px;
  }

  .contact-item:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .contact-item .MuiSvgIcon-root {
    font-size: 24px;
  }

  .footer_logo {
    max-width: 320px;
  }

  .linkedin-link {
    bottom: 0;
  }

  .linkedin-link .MuiSvgIcon-root {
    color: #143774;
  }

  .footer_links .item-menu:first-child {
    padding-left: 0;
  }

}
