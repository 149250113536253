.container__article {
    display: flex;
    flex-direction: column;
}

.article__img-descktop {
    display: none;
}

.article__description-block {
    width: 100%;
}

.article__date {
    margin-bottom: 22px;
    color: var(--color-white);
}

.article__headline {
    line-height: 32px;
    color: var(--color-white);
    margin-bottom: 22px;
}

.article__description {
    color: var(--color-white);
    line-height: 24px;
    margin-top: 18px;
}

.article__hash {
    line-height: 16px;
    color: var(--color-dark-gray);
    margin-top: 14px;
}

.article__img {
    margin: 0 auto;
    width: 100%;
}

.body-page__article .bread-crumbs {
    margin-bottom: 28px;
    padding-top: 10px;
}

.bread-crumbs svg{
    width: 10px;
    color: var(--color-white);
}

.bread-crumbs a{
    color: #C0C0C0;
    text-decoration: none;
}

.bread-crumbs__current-page {
    color: var(--color-white);
    text-shadow: 0px 1px 1px rgba(34, 59, 130, 0.25);
}

.bread-crumbs__back {
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bread-crumbs__back svg {
    color: var(--color-white);
    width: 18px;
}

@media (min-width: 768px){
    .container__article {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }


    .article__description-block {
        width: 468px;
        max-width: 100%;
        margin-right: 32px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .article__headline {
        line-height: 36px;
    }

    .article__block-img {
        width: 588px;
        max-width: 100%;
        position: relative;
    }

    .article__hash {
        margin-top: 14px;
    }

    .article__img {
        margin: 0;
        position: sticky;
        top: 120px;
        right: 0;
    }
    .article__img-mobile {
        display: none;
    }

    .article__img-descktop {
        display: block;
    }
    .article__description {
        margin-top: 0;
    }
}