.container__our-projects .swiper-container{
    padding-bottom: 40px;
}

.container__our-projects .swiper-pagination-bullet {
    background: #BDBDBD;
}

.container__our-projects .swiper-pagination-bullet-active {
    background: var(--color-dark-blue);
}

.body__our-projects {
    padding: 20px 0 20px;
    background: var(--color-white);
}

.our-projects__main-headline {
    color: var(--color-dark-blue);
    font-weight: 500;
    margin-bottom: 12px;
}

.our-projects__item {
    text-decoration: none;
}

.our-projects__item:not(:last-of-type) {
    margin-bottom: 24px;
}

.our-projects__item-headline {
    margin-bottom: 12px;
    text-align: right;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-gray-blue);

    display: flex;
    align-items: center;
}

.our-projects__item-headline h4 {
    white-space: nowrap;
    margin-left: auto;
    font-size: 16px;
}

.our-projects__item-headline:before {
    content: '';
    width: 100%;
    max-width: 100%;
    height: 2px;
    background: #567691;
    border-radius: 4px;
    margin-right: 24px;
}

.our-projects__item-img {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100%;
    height: 280px;
}

.our-projects__item-img img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: 50% 50%;
}

@media (min-width: 768px) {
    .body__our-projects {
        padding: 54px 0 54px;
    }

    .our-projects__main-headline {
        margin-bottom: 30px;
    }
}