.body-page__vacancies {
    padding-top: 100px;
    padding-bottom: 74px;
    position: relative;
    z-index: 2;
    background-image: url("../../Images/backgroundMain.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
}

.body-page__vacancies-accordion {
    margin-top: 20px;
    text-decoration: none;
}

.body-page__vacancies-accordion a{
    text-decoration: none;
}

.headline__list-main {
    color: var(--color-white);
    margin: 13px 0;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.body-page__vacancies-accordion .MuiAccordion-root:before {
    background: #C4C4C4;
}

.body-page__vacancies-accordion .MuiIconButton-root {
    color: var(--color-white);
}

.body-page__vacancies-accordion .MuiAccordionSummary-root {
    padding: 0 5px;
    min-height: auto;
}

.body-page__vacancies-accordion .MuiPaper-elevation1 {
    box-shadow: none;
    border-bottom: 1px solid #C4C4C4;
}

.body-page__vacancies-accordion  .MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.body-page__vacancies-accordion .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
    border-bottom: 1px solid #C4C4C4;
}

.body-page__vacancies-accordion .MuiAccordionSummary-content {
    margin: 0;
}

.body-page__vacancies-accordion .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
}

.body-page__vacancies-accordion .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.body__list-all {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 24px;
    width: 100%;
}

.headline__list {
    color: var(--color-white);
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.list-vacan__item {
    padding-left: 10px;
    color: var(--color-white);
    font-size: 14px;
    position: relative;
}

.list-vacan__item:before {
    content: '';
    background: var(--color-white);
    display: block;
    position: absolute;
    top: 7px;
    left: 2px;
    border-radius: 50%;
    width: 4px;
    height: 4px;
}

.headline__list-plus {
    margin: 10px 0;
}

.description__list-plus {
    color: var(--color-white);
}

.list-vacan__item:not(:last-child) {
    margin-bottom: 8px;
}

.link-vacancies {
    text-decoration: none;
}

body .MuiMenu-paper {
    background: rgba(9, 20, 26, 1);
    color: var(--color-white);
}

.button-vacancies {
    display: block;
    margin: 0 auto;
    margin-top: 16px;
}

@media (min-width: 600px){
    .body__list-all {
        grid-template-columns: repeat(2,1fr);
    }
}

@media (min-width: 768px){
    .body-page__vacancies {
        padding-top: 160px;
        padding-bottom: 125px;
    }
    .body-page__vacancies-accordion {
        margin-top: 32px;
    }
    .button-vacancies {
        margin: 0;
        margin-left: auto;
        margin-top: 16px;
    }
}

@media (min-width: 1200px){
    .body-page__vacancies {
        min-height: 1200px;
    }
}
