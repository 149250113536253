.header {
  background: rgba(21, 48, 66, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
}

.body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body-list {
  display: none;
}

.main-list {
  display: flex;
  align-items: center;
}

.body-list .list-menu__items {
  margin-right: 20px;
  position: relative;
}

.list-menu__items:after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 24px;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: var(--color-white);
}

.item-menu__link {
  color: var(--color-white);
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  text-shadow: 0px 1px 1px rgba(34, 59, 130, 0.25);
}

.item-menu__link:after {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: var(--color-white);
  transition: 0.3s;
}

.item-menu__link-active:after {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: var(--color-white);
}

.item-menu__link:hover:after {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: var(--color-white);
}

.item-menu__burger {
  position: relative;
}

.burger-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 300;
}

.item-menu__link-burger {
  position: relative;
  text-decoration: none;
  color: var(--color-white);
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
}

.burger-menu__item:not(:last-child) {
  margin-bottom: 28px;
}

.burger-item-menu__link-active {
  padding-left: 20px;
  font-weight: 500;
}

.burger-item-menu__link-active:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  background: var(--color-white);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.item-language {
  color: var(--color-gray);
  padding: 2px 4px;
  position: relative;
  cursor: pointer;
}

.active-language {
  color: var(--color-white);
}

.active-language:after {
  content: '';
  display: block;
  position: absolute;
  width: 70%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: var(--color-white);
}

.item-menu {
  padding: 0 16px;
  position: relative;
}

.item-menu:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 16px;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: var(--color-white);
}

.burger-menu__top {
  display: block;
  width: 32px;
  height: 2px;
  background: var(--color-white);
  margin-bottom: 10px;
}

.burger-menu__bottom {
  display: block;
  width: 12px;
  height: 2px;
  background: var(--color-white);
  margin-left: auto;
  transition: 0.3s;
}

.open__burger-menu .burger-menu__bottom {
  transform: translateY(-12px);
}

.body__burger-menu {
  background: rgba(3, 33, 49, 0.9);
  height: 100vh;
  max-width: 100%;
  width: 100%;
  padding: 16px 28px 85px;
}

.header-menu__main-headline {
  color: var(--color-white);
  margin-bottom: 52px;
  padding-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
}

.body__burger-menu .line-after__white:after {
  width: 40px;
}

.body__burger-menu .list-language__items {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 85px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.burger-menu__item-language {
  position: relative;
  color: #ABABAB;
}

.burger-menu__active-language {
  color: var(--color-white);
}

.burger-menu__active-language:after {
  content: '';
  display: block;
  position: absolute;
  width: 70%;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: var(--color-white);
}

body .MuiDrawer-paper {
  top: 76px;
}

body .MuiPaper-root {
  background: none;
  width: 100%;
}

@media (min-width: 769px) {
  .body-list__mobile {
    display: none;
  }

  .header {
    padding: 40px 0 37px;
  }

  .body-list {
    display: flex;
    align-items: center;
  }
}
