.body__our-vacancies .swiper-pagination-bullet {
  background: #BDBDBD;
}

.body__our-vacancies .swiper-pagination-bullet-active {
  background: #143774;
}

.body__our-vacancies .swiper-slide-active {
  transform: scale(1.05);
}

.body__our-vacancies .swiper-pagination {
  bottom: 0;
}

.body__our-vacancies .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 20px;
  display: flex;
  height: 12px;
  align-items: center;
  justify-content: center;
}

.our-vacancies__main-headline {
  color: var(--color-dark-blue);
  padding-left: 15px;
}

.our-vacancies__main-headline.line-after__blue:after {
  left: 15px;
}

.body__our-vacancies {
  width: 100%;
  max-width: 100%;
  padding-top: 22px;
  background: var(--color-white);
}

.body__swiper-vacancies {
  padding: 40px 0 56px 0;
  margin: 0 auto;
}

.container__our-vacancies {
  padding-bottom: 18px;
}

.container__swiper-item-vacancies {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #9E9E9E;
  max-width: 300px;
  width: 100%;
  height: 196px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  text-decoration: none;
}

.swiper-item__headline-vacancies {
  color: var(--color-white);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  max-width: 204px;
  text-align: center;
}

.swiper-item__description-vacancies {
  color: var(--color-white);
  text-transform: uppercase;
}

@media (min-width: 768px){
  .body__our-vacancies {
    padding-top: 54px;
  }
  .container__our-vacancies {
    padding-bottom: 50px;
  }
}
