.body__banner {
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.body__banner:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.body__banner .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 48px;
}

.swiper-item__headline:not(:first-child) {
    margin-bottom: 20px;
    padding-bottom: 8px;
}

.swiper-item__headline {
    color: var(--color-white);
}

.swiper-item__description {
    color: var(--color-white);
    max-width: 908px;
}

.swiper-item__description:not(:last-of-type) {
    margin-bottom: 10px;
}

.swiper-item__description:last-of-type {
    margin-bottom: 50px;
}

.swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-pagination-bullet {
  margin-top: 2px;
    width: 8px;
    height: 8px;
    background: var(--color-white);
    transition: 0.3s;
    display: block;
    opacity: 1;
}

.swiper-pagination-bullet-active {
  margin-top: 0px;
  width: 24px;
    height: 12px;
    border-radius: 32px;
    background: var(--color-dark-blue);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.swiper-item__button {
    display: block;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
}

@media (min-width: 768px){
    .swiper-item__headline:not(:first-child) {
        margin-bottom: 26px;
    }
    .body__banner {
        background-size: cover;
    }
    .body__banner .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 60px;
    }
    .swiper-item__button {
        margin: 0;
        margin-right: auto;
    }
}
