h1, h2 {
  font-size: var(--font-size-h1);
  font-weight: 400;
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: 500;
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: 400;
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: 300;
}

.p1 {
  font-size: var(--font-size-p1);
  font-weight: 400;
}

.p2 {
  font-size: var(--font-size-p2);
  font-weight: 400;
}

.p3 {
  font-size: var(--font-size-p3);
  font-weight: 400;
}

.container {
  width: 1232px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.button-opacity {
  max-width: 240px;
  width: 100%;
  padding: 8px 0;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  color: var(--color-white);
  background: transparent;
  transition: 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.button-opacity:hover {
  background: rgba(21, 48, 66, 0.75);
}

.button-opacity__disabled {
  opacity: 0.4;
  cursor: auto;
}

.button-opacity__disabled:hover {
  background: transparent;
}

.button-blue {
  max-width: 240px;
  width: 100%;
  padding: 8px 0;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  color: var(--color-white);
  background: rgba(21, 48, 66, 0.75);
  transition: 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.button-blue {
  background: transparent;
}

.line-after__white {
  position: relative;
}

.line-after__white:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 40px;
  background: var(--color-white);
}

.line-after__blue {
  position: relative;
}

.line-after__blue:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 40px;
  background: var(--color-gray-blue);
}

.body__swiper-banner {
  padding-top: 170px;
  padding-bottom: 144px;
}

.main-style__section {
  padding-top: 100px;
  padding-bottom: 74px;
  background-image: url("../Images/backgroundMain.jpeg");
  background-size: cover;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

.main-background__img {
  background-image: url("../Images/backgroundMain.jpeg");
  position: relative;
  z-index: 2;
  background-size: cover;
}

.main-style__headline {
  color: var(--color-white);
  padding-bottom: 5px;
}

.main {
  position: relative;
}

.to-top {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  right: 0;
  left: 0;
  margin: 0 auto;
  background: #143774;
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.07), 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1199;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
}

.body__separators {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.body__separators-line {
  width: 100%;
  height: 1px;
  background: var(--color-white);
  box-shadow: 0px 1px 1px var(--color-black);
  border-radius: 4px;
}

.separators-line__left {
  margin-right: 36px;
}

.separators-line__right {
  margin-left: 36px;
}

.body__separators-square {
  min-width: 34px;
  min-height: 24px;
  max-width: 34px;
  max-height: 24px;
  background: transparent;
}

@media (min-width: 768px) {
  .body__swiper-banner {
    padding-top: 232px;
    padding-bottom: 216px;
  }

  .main-style__section {
    padding-top: 160px;
    padding-bottom: 125px;
  }

  .line-after__white:after {
    width: 64px;
  }

  .line-after__blue:after {
    width: 64px;
  }
}

@media (min-width: 1024px) {
  h1, h2 {
    font-size: var(--font-size-h1-adaptive);
    font-weight: 500;
  }

  h3 {
    font-size: var(--font-size-h3-adaptive);
    font-weight: 700;
  }

  h4 {
    font-size: var(--font-size-h4-adaptive);
    font-weight: 600;
  }

  h5 {
    font-size: var(--font-size-h5-adaptive);
    font-weight: 500;
  }

  .p1 {
    font-size: var(--font-size-p1-adaptive);
    font-weight: 500;
  }

  .p2 {
    font-size: var(--font-size-p2-adaptive);
  }

  .p3 {
    font-size: var(--font-size-p3-adaptive);
  }
}
