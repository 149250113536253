* {
    --color-white: #fff;
    --color-dark-blue: #223B82;
    --color-gray-blue: #567691;
    --color-gray-black: #272D3A;
    --color-gray: #E6E6E6;
    --color-dark-gray: #9E9E9E;
    --color-black: #152D3A;
 
    --padding-card-offer: 17px 52px 26px 16px;
    --padding-card-offer-adaptive: 14px 8px 24px;

    --main-margine: 24px;

    --line-height-1: 48px;
    --line-height-2: 32px;
    --line-height-3: 24px;
    --line-height-4: 18px;
    --line-height-5: 16px;

    --font-size-h1-adaptive: 44px;
    --font-size-h1: 24px;
    --font-size-h3-adaptive: 24px;
    --font-size-h3: 18px;
    --font-size-h4-adaptive: 20px;
    --font-size-h4: 16px;
    --font-size-h5-adaptive: 20px;
    --font-size-h5: 14px;

    --font-size-p1: 14px;
    --font-size-p1-adaptive: 16px;
    --font-size-p2: 12px;
    --font-size-p2-adaptive: 14px;
    --font-size-p3: 10px;
    --font-size-p3-adaptive: 12px;
}
